








































































































































import Vue from 'vue';
import OptionChannel from './OptionChannel.vue'

Vue.component('OptionChannel', OptionChannel);

export default Vue.extend({
  name: 'Channel',

  props: {
    socket: {},
  },

  data() {
      return {
        dialog: false,
        passwordDialog: false,
        showInput: false,
        valid: true,
        name: '',
        password: '',
        searchChannel: '',
        awaitingSearch: false,
      }
  },

  computed: {
    idCurrentChannel: {
      get() {
        return this.$store.getters.getIdCurrentChannel;
      },
      set(id: number) {
        this.$store.commit('setIdCurrentChannel', id);
      }
    },
    channels: {
      get() {
        return this.$store.getters.getChannels;
      },
      set(channels: unknown) {
        this.$store.commit('setChannels', channels);
      }
    },
    myChannels: {
      get() {
        return this.$store.getters.getMyChannels;
      },
      set(myChannels: unknown) {
        this.$store.commit('setMyChannels', myChannels);
      }
    },
    chatDirect: {
      get() {
        return this.$store.getters.getChatDirect;
      },
      set(chatDirect: unknown) {
        this.$store.commit('setChatDirect', chatDirect);
      }
    },
    directChannels: {
      get() {
        return this.$store.getters.getDirectChannels;
      },
      set(directChannels: unknown) {
        this.$store.commit('setDirectChannels', directChannels);
      }
    },
    user() {
      return this.$store.getters.getUser;
    },
    filteredChannels(): unknown {
      return this.channels.filter((channel) => {
        return channel.name.match(this.searchChannel);
      })
    },
    rules() {
      const rules = [];
      let rule = v => !!v;
      rules.push(rule);
      let rule2 = v => (v && v.length <= 8) || 'must be less than 8 characters';
      rules.push(rule2);
      let rule3 = v => !this.channels.some(channel => channel.name === v) || 'already exists';
      rules.push(rule3);
      return rules;
    },
    rulesPassword() {
      const rules = [];
      let rule = v => v.length <= 16 || 'must be less than 16 characters';
      rules.push(rule);
      return rules;
    }
  },

  methods: {
    isAdmin(listAdminsId: [number]) {
      return listAdminsId.includes(this.user.id);
    },
    alreadyJoin(toJoin: number) {
      return this.myChannels.some(channel => channel.id == toJoin);
    },
    joinChannel(idChannel) {
      this.socket.emit('join', {id: idChannel, password: this.password});
      this.searchChannel = '';
      this.$emit('fetchChannels');
      this.password = '';
      this.passwordDialog = false;
    },
    leaveChannel(idChannel) {
      this.socket.emit('leave', { channelId: idChannel })
      if (this.currentIdCR == idChannel)
        this.currentIdCR = 0;
    },
    createChannel() {
      if (this.valid) {
        if (this.password == '')
          this.$http.post('/channel', {name: this.name, public: true}).then(() => {
            this.$emit('fetchChannels');
          });
        else
          this.$http.post('/channel', {name: this.name, public: false, password: this.password }).then(() => {
            this.$emit('fetchChannels');
          });
        this.dialog = false;
        this.name = '';
        this.password = '';
      }
    },
    changeChannel(idChannel) {
      this.idCurrentChannel = idChannel;
    },
  },

  watch: {
    searchChannel() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.$emit('fetchChannels');
          this.awaitingSearch = false;
        }, 500);
        this.awaitingSearch = true;
      }
    },
  }
})
