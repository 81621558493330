


















































































import Vue from 'vue';
import io from 'socket.io-client';

export default Vue.extend({
    name: 'PlayerChannel',

    props: {
      socket: {},
    },

    data() {
      return {
        invitDialog: false,
      }
    },

    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      idCurrentChannel: {
        get() {
          return this.$store.getters.getIdCurrentChannel;
        },
        set(value: number) {
          this.$store.commit('setIdCurrentChannel', value);
        }
      },
      myChannels() {
        return this.$store.getters.getMyChannels;
      },
      currentChannel() {
        return this.$store.getters.getCurrentChannel;
      },
      notifySocket() { return this.$store.getters.getNotifySocket; },
      gameSocket: {
        get() {
          return this.$store.getters.getGameSock;
        },
        set(value: undefined) {
          this.$store.commit('setGameSock', value);
        },
      },
      chatDirect: {
        get() {
          return this.$store.getters.getChatDirect;
        },
        set(value: boolean) {
          this.$store.commit('setChatDirect', value);
        },
      },
      directChannels() {
        return this.$store.getters.getDirectChannels;
      },
    },

    methods: {
      isPlayerMuted(idPlayer) {
        if (this.currentChannel.muted.some(mute => mute.user.id === idPlayer))
          return 'UNMUTE';
        return 'MUTE';
      },
      isPlayerBanned(idPlayer) {
        if (this.currentChannel.banned.some(ban => ban.user.id === idPlayer))
          return 'UNBAN';
        return 'BAN';
      },
      isPlayerAdmin(idPlayer) {
        if (this.currentChannel.adminId.includes(idPlayer))
          return "UNSET ADMIN";
        else
          return "SET ADMIN";
      },
      isPlayerBlocked(idPlayer) {
        if (this.user.blocked.includes(idPlayer))
          return "UNBLOCK";
        return "BLOCK";
      },
      mutePlayer(idPlayer) {
        if (idPlayer !== this.currentChannel.owner.id)
          this.socket.emit('mute', {userId: idPlayer, channelId: this.idCurrentChannel});
        else
          this.$toast.error('You cannot mute the owner of the channel');
      },
      kickPlayer(idPlayer) {
        if (idPlayer !== this.currentChannel.owner.id)
          this.socket.emit('leave', {userId: idPlayer, channelId: this.idCurrentChannel});
        else
          this.$toast.error('You can\'t kick the owner of the channel');
      },
      banPlayer(idPlayer) {
        if (idPlayer !== this.currentChannel.owner.id)
          this.socket.emit('ban', {userId: idPlayer, channelId: this.idCurrentChannel});
        else
          this.$toast.error('You can\'t ban the owner of the channel');
      },
      blockPlayer(idPlayer) {
        this.$http.put(`/user/me/block/${idPlayer}`).then(() => {
          this.$http.get('/user/me').then(({data}) => {
            this.$store.commit('setUser', data);
          });
        });
      },
      setAdmin(idPlayer) {
        this.socket.emit('admin', {userId: idPlayer, channelId: this.idCurrentChannel});
      },
      cancelInvit() {
        this.gameSocket.disconnect();
        this.notifySocket.off('notify');
        this.$store.dispatch('enableNotify');
        this.invitDialog = false;
      },
      directMessage(playerId: number) {
        this.socket.once('channelMeDM', (channelMeDM) => {
          if (!channelMeDM.some(dm => dm.users.some(user => user.id === playerId)))
            this.socket.emit('joinDM', playerId);
          else
            this.idCurrentChannel = channelMeDM.find(dm => dm.users.some(user => user.id === playerId)).id;
          this.chatDirect = true;
        });
        this.idCurrentChannel = 0;
        this.socket.emit('channelMeDM');
      },
      invite(id: number) {
        if (this.status != 'orange') {
          this.gameSocket = io(`https://${window.location.hostname}:${process.env.VUE_APP_BACKEND_PORT}/pong`, {
            transportOptions: {
              polling: { extraHeaders: { Authorization: 'Bearer ' + localStorage.getItem('token') } },
            },
          });
          this.gameSocket.on('info', () => {
              this.gameSocket.emit('room');
          });
          this.gameSocket.on('room', (code) => {
            this.dialog = false;
            this.$store.commit('setGameRoom', code);
            const payload = {
              id: id,
              sender: this.user.id,
              message: this.user.username + " has invited you to play",
              roomCode: code
            };
            this.notifySocket.emit('notify', payload);
            this.notifySocket.on('notify', (data) => {
              if (data.sender == id && this.gameSocket.connected) {
                this.invitDialog = false;
				this.notifySocket.off('notify');
				this.$store.dispatch('enableNotify');
                this.$router.push('/pregame');
              }
            });
          });
        }
      },

      status(status: number) {
        if (status == 1)
          return 'blue';
        else if (status == 2)
          return 'orange';
        else if (status == 3)
          return '#49be25';
        else
          return 'grey';
      }
    },
})
