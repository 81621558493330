





















































import Vue from 'vue';

export default Vue.extend({
    name: 'Chat',

    props: {
      socket: {},
    },

    data() {
      return {
        input: '',
        messages: [],
        logs: [],
        sendNotif: true,
      }
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        idCurrentChannel() {
          return this.$store.getters.getIdCurrentChannel;
        },
        chatDirect() {
          return this.$store.getters.getChatDirect;
        },
    },

    methods: {
      senderWidth(msg) {
        if (msg.length > 70)
          return "65%";
        if (msg.length > 39)
          return "50%";
        if (msg.length > 20)
          return "25%";
        if (msg.length > 30)
          return "30%";
        return "25%"
      },
      isBlocked(idPlayer) {
        if (this.user.blocked.indexOf(idPlayer) == -1 || this.chatDirect)
          return false;
        return true;
      },
      sendMsg() {
        if (this.input == '' || this.idCurrentChannel == 0)
          return;
        if (this.input.length >= 256) {
          this.input = '';
          this.$toast.error('Message too long');
          return;
        }
        if (this.chatDirect)
          this.socket.emit('textDM', {
            text: this.input,
            channelId: this.idCurrentChannel,
          });
        else
          this.socket.emit('text', {
            id: this.idCurrentChannel,
            user: this.user,
            value: this.input,
          });
        this.input = '';
      },
      cleanLogs() {
        if (this.idCurrentChannel == 0)
            this.messages = [];
        if (this.idCurrentChannel > 0) {
          if (this.chatDirect) {
            this.socket.emit('channelDM', this.idCurrentChannel);
          }
          else
            this.socket.emit('channel', {
              id: this.idCurrentChannel
            });
        }
      },
      pushLogs() {
        this.messages = [];
        for (let i in this.logs)
          this.messages.push( { sender: this.logs[i].user, msg:  this.logs[i].message} )
        if (this.idCurrentChannel != 0)
          setTimeout(() => {
            document.getElementById('Chat').scrollTop = document.getElementById('Chat').scrollHeight;
          }, 100);
      }
    },

    created() {
        this.$watch(() => this.idCurrentChannel, () => { this.cleanLogs(); },{ immediate: true })
        this.socket.on("text", data => {
          if (data.id == this.idCurrentChannel && !this.chatDirect)
          {
            this.messages.push( { sender: data.user, msg: data.value } );
            setTimeout(() => {
              if (document.getElementById('chat'))
                document.getElementById('Chat').scrollTop = document.getElementById('Chat').scrollHeight;
            }, 100);
          }
        });
        this.socket.on("textDM", data => {
          if (data.channelId == this.idCurrentChannel && this.chatDirect)
          {
            this.messages.push( { sender: data.user, msg: data.text } );
            setTimeout(() => {
              document.getElementById('Chat').scrollTop = document.getElementById('Chat').scrollHeight;
            }, 100);
          }
          else if (data.user.id != this.user.id) {
            if (this.sendNotif) {
              this.sendNotif = false;
              this.$toast.info('You have a new message from ' + data.user.username, {
                position: 'top-center',
                duration: 5000
              });
              setTimeout(() => {
                this.sendNotif = true;
              }, 10000);
            }
          }
        });
        this.socket.on("channel", data => {
          if (data.id == this.idCurrentChannel && !this.chatDirect)
          {
            this.logs = data.logs;
            this.pushLogs();
          }
        });
        this.socket.on("channelDM", data => {
          if (data.id == this.idCurrentChannel && this.chatDirect)
          {
            this.logs = data.logs;
            this.pushLogs();
          }
        });
    },
})
