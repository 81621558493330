















































import Vue from 'vue';

export default Vue.extend({
    name: 'OptionChannel',

    props: {
      channel: {},
    },

    data() {
      return {
        dialog: false,
        oldPassword: '',
        newPassword: '',
        valid: false,
      }
    },

    methods: {
        leaveChannel() {
          this.$emit('leaveChannel');
        },
        changePassword() {
          this.$http.post('/channel/' + this.channel.id + '/change', {
            oldPassword: this.oldPassword, newPassword: this.newPassword
          }).catch(error => {
            if (error.response.status == 403) {
              this.$toast.error('Wrong password', { position: 'top-left' });
            }
          });
          this.oldPassword = '';
          this.newPassword = '';
          this.dialog =! this.dialog;
        }
    },

    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      rules() {
        const rules = [];
        let rule = v => !!v;
        rules.push(rule);
        return rules;
      }
    }
})
