import { render, staticRenderFns } from "./PlayerChannel.vue?vue&type=template&id=1531dee3&scoped=true&"
import script from "./PlayerChannel.vue?vue&type=script&lang=ts&"
export * from "./PlayerChannel.vue?vue&type=script&lang=ts&"
import style0 from "./PlayerChannel.vue?vue&type=style&index=0&id=1531dee3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1531dee3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBadge,VBtn,VCard,VCardActions,VCardTitle,VDialog,VDivider,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VProgressCircular,VSheet})
